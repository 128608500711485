import React from "react"
import Layout from "../../components/layout-tips"

export default () => (
  <Layout title="Helpful Hints">
    <h2>Helpful Hints</h2>
    <div className="clear-block">
      <div id="node-13" className="node">


        <div className="content clear-block">
          <p><img src="/images/207-amanda-250x333.jpg" className="float-right" width="250"
                  height="333" alt=""/> Some helpful hints apply to all age groups. Here are a couple of great importance that
            apply to all our students.</p>
          <ol>
            <li>Practicing. This is always a problem. Until students really enjoy what they are doing, they probably
              won’t practice the way we hope they will. However, without practice it is hard to move forward, and
              without forward movement the students become discouraged and want to quit. This is not what we want as
              their teachers. To solve this problem I have them “practice” at their lessons. For my older students I am
              honest with them and discuss the importance of playing a piece several times and working to get it right,
              then we will play it at least six time, or one for every day of the week they have not been to lessons.
              After about a month of this I find most of my students are starting to practice at home, and lessons
              become more fun. For my little ones, I might tell them, “I like that piece, will you please play it some
              more for me,” or I will tell them, “I know you can do better on that, let’s try it again.” Practicing
              during lessons time, does take time from learning new things, but it is too important and fussing at a
              student about it seldom works.
            </li>
            <li>Hand position. Students seem to want to play with their fingers flat, which leaves the valuable thumb
              hanging in mid-air off the keyboard. Younger students think one finger can do all the playing. To solve
              this problem I ask my students if they have ever watched a spider walk.<br/>
              A big smile and a yes is always the response. I ask them to show me on the piano bench. I them tell then
              they have created the “Magic Spider”, and he will now walk on the piano keys. WOW, it works, fingers go up
              and the thumb is now on the keyboard. With my older students I discuss with them how much difference this
              makes in the sound and expression they can create in their music. When ever the fingers go down I simply
              ask who stepped on your spider to flatten him out and the fingers pop up again. Hurrah for the “Magic
              Spider”.
            </li>
            <li>Attitudes and Moods. The Animal Note Method allows the beginning music student to actually have fun
              while learning to Note Read and develop good Timing. What a blessing for a teacher. If your student
              arrives with an “I just never have time to practice”, or a bad mood from something that happened during
              their school day, it is easy to be firm and constructive with them, moving forward with a productive
              lesson of practice and learning. Small positive comments are easily given because the child is not having
              difficulty reading the notes, so a way to success can be found and the task satisfactorily accomplished.
              Timing can be actually studied and learned because the child does not have to pause to figure out the next
              note. Hand position can be corrected and become a good habit; because the child can think about something
              other than “what note is that?” For you as the teacher, you can relax, become creative, and feel the
              gratification of a job well done. The Attitude and Mood of the lesson can be a smile and happiness.
            </li>
            <li>Be honest with your students, explain why something is important.
              <p>The very first student I ever taught also taught me a very valuable lesson. Children like to understand
                “why” something is important to learn or why it is important to do something in a particular way. I did
                not always know the answers; I had just been taught to do it that way and had not questioned my
                teachers. But Nancy wanted to know, and it was my responsibility to find the answers for her as I was
                her teacher. I spent a lot of time researching the two years I taught her, and I learned as much, and
                possibly more than she did. I learned to be honest with my students and explain the whys, even if they
                were not asked. Nancy went on to in music, and it brings her joy and relaxation in her busy adult
                world.</p>
              <p>One “why” that is so important to explain is playing with the finger tips. Why doesn’t a flat finger
                work just as well? They might understand they are to use their hands like spiders on the keyboard. But
                is it really all that important, and it isn’t easy to remember to do so I have my students play as fast
                as possible with flat fingers, and then on their finger tips. I then explain how using all their finger
                joints, and all the joints of each finger will provide flexibility and thus quicker and easier movement
                on the keys. Have them try to move their hand up or down the keyboard by passing the thumb under the
                hand or the hand over the thumb. They will have to arch their fingers. Help them recognize this
                fact. </p>
              <p>Another important why that needs to be talked about is practicing at least a little 4 or 5 days a week.
                This is not an easy one to do, but if the child does not hear the difference it makes, they will always
                be just too busy. If my students do not do it I have them practice one line at the lesson 6 times, then
                play a line that has not been practiced. They can always here and feel the difference. This usually will
                take more than one time to get the point across, but I always emphasis why it is so vitally important. A
                child or parent will quit if progress is not made, and why not, music is not fun if the student does not
                get better as time passes. </p>
              <p>This is only a couple of many chances you will have to explain important elements of music to your
                students, rather than just telling them to do this or that. You will find them much more cooperative and
                willing to work with you if you help them figure out the principle you are teaching.</p></li>
          </ol>
          <h3>Testimonial</h3>
          <p><i>"Glenna, Oh I can't wait to start Your product! (*smile*) I feel a vigorous expectancy that the children
            and I will enjoy working with the animal notes. With the animal notes we can go at our own pace while we
            become familiar with the process of learning to play the notes and eventually any instrument; and not become
            frustrated at the very beginning."<br/>
            - Joyce </i></p>
        </div>

        <div className="clear-block">
          <div className="meta">
          </div>

        </div>

      </div>
    </div>
  </Layout>
)
